'use client';

import { useState } from 'react';
import { ArrowRightIcon, MailSolidIcon } from '@dop-ui/icons/react/dop/24';
import * as Dialog from '../../../../../shared/ui/dialog';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { TwoFactorAuthTools } from '../../../types';
import { EmailContent } from './email-content';

import styles from './styles/dialog.module.css';

export interface Props {
  toolsInfo?: TwoFactorAuthTools;
  onClickConfirm?: () => void;
}

export function TfaEmailRegisterDialog({ toolsInfo, onClickConfirm }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className={styles.DialogTrigger}>
        <div className="size-[60px] bg-[#EEF1F7] rounded-full flex items-center justify-center">
          <MailSolidIcon size={36} className="fill-white" />
        </div>
        <div className="mt-[16px] min-w-[144px]">
          <p className="text-[#363636] text-base font-semibold text-start">
            {t('auth.tfa.tool.email')}
          </p>
          <p className="mt-[4px] text-[#363636] text-xs font-normal text-wrap text-start">
            {t('auth.tfa.selectTfaToolDesc.mail.short')}
          </p>
        </div>
        <ArrowRightIcon size={20} className="text-[#AAAAAA] !justify-start" />
      </Dialog.Trigger>
      <Dialog.Title className="hidden">
        {t('auth.tfa.selectTfaToolTitle')}
      </Dialog.Title>
      <Dialog.Content
        className={styles.DialogContent}
        useOverlay
        usePortal
        onOpenAutoFocus={(e) => e.preventDefault()} // 내부 툴팁이 포커스를 잡아서 추가
        onCloseAutoFocus={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <EmailContent
          toolsInfo={toolsInfo}
          onOpenChange={setOpen}
          onClickConfirm={onClickConfirm}
        />
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default TfaEmailRegisterDialog;
