import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClockIcon, InfoIcon } from '@dop-ui/icons/react/dop/24';
import { Button } from '../../../../../shared/ui/button';
import * as Tooltip from '../../../../../shared/ui/tooltip';
import { useToastMessage } from '../../../../../shared/ui/toast';
import { isValidEmail, maskEmail } from '../../../../../shared/lib/utils';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { TwoFactorAuthTools } from '../../../types';
import { registerMail } from '../../../api/tfa-register';
import { QUERY_KEY_TFA_TOOLS } from '../../../api/tfa-get-tfa-tools';
import { requestMailEnrollCode } from '../../../api/tfa-enroll-code';
import { timerUtil } from './utils/timer';
import { formatTime } from './utils/timer-format';
// TODO: - TextField 대체 필요
import { ClearableInput } from './components/clearable-input';

interface Props {
  toolsInfo?: TwoFactorAuthTools;
  onClickConfirm?: () => void;
  onOpenChange: (open: boolean) => void;
}

export function EmailContent({
  toolsInfo,
  onClickConfirm,
  onOpenChange,
}: Props) {
  const { t } = useTranslation();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const defaultEmail = toolsInfo?.mailAddress ? toolsInfo.mailAddress : '';

  const [email, setEmail] = useState(maskEmail(defaultEmail));
  const [enrollCode, setEnrollCode] = useState('');
  const [sendCodeRequest, setSendCodeRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [timerEnd, setTimerEnd] = useState(false);

  const requestMailEnrollCodeMutation = useMutation({
    mutationFn: (email: string) => requestMailEnrollCode(email),
    onSuccess: () => {
      endTimerSequence();
      toaster.info(
        t('auth.tfa.enroll.mailSendSuccess', { email: maskEmail(email) }),
      );
      setSendCodeRequest(true);
      startTimerSequence();
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickSendCode = () => {
    if (!isValidEmail(email) && !(email === maskEmail(defaultEmail))) {
      toaster.warning(t('auth.tfa.email.invalidEmail'));
      return;
    }

    if (email === maskEmail(defaultEmail)) {
      requestMailEnrollCodeMutation.mutate(defaultEmail);
    } else {
      requestMailEnrollCodeMutation.mutate(email);
    }
  };

  const registerEmailAndCodeMutation = useMutation({
    mutationFn: ({ code, email }: { code: string; email: string }) =>
      registerMail(code, email),
    onSuccess: async () => {
      toaster.info(t('auth.tfa.mailRegisterSuccess'));
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TFA_TOOLS] });
      endTimerSequence();
      onOpenChange(false);
      onClickConfirm && onClickConfirm();
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickRegister = () => {
    if (timerEnd) {
      setErrorMessage(t('auth.tfa.error.timeOver.enroll'));
      toaster.warning(t('auth.tfa.error.timeOver.enroll'));
      return;
    }

    if (!sendCodeRequest) {
      toaster.warning(t('auth.tfa.enrollCode.sendCodeFirst'));
      return;
    }

    if (!enrollCode) {
      toaster.warning(t('auth.tfa.enrollCode.invalidEnrollCode'));
      return;
    }

    if (!isValidEmail(email) && !(email === maskEmail(defaultEmail))) {
      toaster.warning(t('auth.tfa.email.invalidEmail'));
      return;
    }

    if (email === maskEmail(defaultEmail)) {
      registerEmailAndCodeMutation.mutate({
        code: enrollCode,
        email: defaultEmail,
      });
    } else {
      registerEmailAndCodeMutation.mutate({ code: enrollCode, email });
    }
  };

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setSendCodeRequest(false);
  };

  const timerDuration = 30 * 60; // 30 minutes

  const [timeRemaining, setTimeRemaining] = useState(() => {
    const savedEndTime = timerUtil.getEndTime();
    if (savedEndTime) {
      const endTime = new Date(savedEndTime).getTime();
      const currentTime = new Date().getTime();
      const remainingTime = Math.floor((endTime - currentTime) / 1000);
      return remainingTime > 0 ? remainingTime : timerDuration;
    }
    return timerDuration;
  });

  const [isRunning, setIsRunning] = useState(() => {
    const savedIsRunning = timerUtil.getIsRunning();
    return savedIsRunning ? (JSON.parse(savedIsRunning) as boolean) : false;
  });

  const startTimerSequence = () => {
    setIsRunning(true);
    setErrorMessage('');
    setTimerEnd(false);
  };

  const endTimerSequence = () => {
    timerUtil.clearTimer();
    setTimeRemaining(timerDuration);
    setIsRunning(false);
  };

  const timerEndedSequence = () => {
    timerUtil.clearTimer();
    setIsRunning(false);
    setTimeout(() => {
      setErrorMessage(t('auth.tfa.error.timeOver.enroll.email'));
      setTimerEnd(true);
    }, 100);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRunning && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTimeRemaining = prevTime - 1;
          if (newTimeRemaining <= 0) {
            clearInterval(timer);
            timerEndedSequence();
          }
          return newTimeRemaining;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  return (
    <>
      <p className="w-full font-[600] text-[20px] text-start text-[#363636]">
        {t('auth.tfa.selectTfaToolTitle')}
      </p>
      <p className="mt-[16px] w-full font-[400] text-[14px] text-start text-[#363636] whitespace-pre-wrap">
        {t('auth.tfa.selectTfaToolDesc.mail')}
      </p>
      <div className="w-full h-[21px] mt-[16px] flex items-center justify-start">
        <span className="font-[500] text-[14px] text-start text-[#363636]">
          {t('auth.tfa.tool.email')}
        </span>
        <Tooltip.Root>
          <Tooltip.Trigger className="flex items-center size-[14px] ml-[4px] text-[#8D96A3]">
            <InfoIcon size={13} />
          </Tooltip.Trigger>
          <Tooltip.Content align="center">
            {t('auth.tfa.tool.emailDesc.noEmail')}
          </Tooltip.Content>
        </Tooltip.Root>
      </div>
      <div className="w-full h-[40px] mt-[8px] flex items-center justify-start">
        <ClearableInput
          className="w-[275px]"
          placeholder={t('auth.tfa.enroll.mail.placeholder')}
          value={email}
          inputDisabled={
            (defaultEmail !== '' && maskEmail(defaultEmail) === email) ||
            sendCodeRequest
          }
          onChange={onChangeEmail}
          onClear={() => onChangeEmail('')}
        />
        <Button
          className="ml-[10px]"
          size="medium"
          variant="solid"
          colorset="level2"
          shape="rect"
          onClick={onClickSendCode}
        >
          {t('auth.tfa.enrollCode.send')}
        </Button>
      </div>
      <p className="w-full mt-[16px] font-[500] text-[14px] text-start text-[#363636]">
        {t('auth.tfa.enrollCode')}
      </p>
      <ClearableInput
        className="w-full mt-[8px]"
        placeholder={t('auth.tfa.enrollCode.placeholder')}
        value={enrollCode}
        onChange={setEnrollCode}
        onClear={() => setEnrollCode('')}
        rightItems={
          isRunning ? (
            <>
              <ClockIcon size={16} className="pt-[2px] text-[#E84B4B]" />
              <span className="text-[#E84B4B] text-[14px] font-[400]">
                {formatTime(timeRemaining)}
              </span>
            </>
          ) : undefined
        }
      />
      {errorMessage && (
        <div className="w-full mt-[8px] text-[#E84B4B] text-[12px] font-[400] text-start whitespace-pre-wrap">
          {errorMessage}
        </div>
      )}
      <div className="w-full mt-[24px] flex gap-[16px] justify-end">
        <Button
          size="medium"
          variant="ghost"
          colorset="level1"
          shape="rect"
          onClick={() => {
            onOpenChange(false);
            endTimerSequence();
          }}
        >
          {t('dialog.cancel')}
        </Button>
        <Button
          size="medium"
          variant="solid"
          colorset="level1"
          shape="rect"
          onClick={onClickRegister}
        >
          {t('auth.tfa.selectTfaToolTitle.short')}
        </Button>
      </div>
    </>
  );
}
